module Main exposing (getCampaignPath, main)

import Briefing
import Browser
import CampaignForm exposing (Campaign)
import Components
import Css.Global
import ElmViewComponents
import FeatherIcons
import Html.Styled exposing (Html, div, text)
import Html.Styled.Attributes as Attrs exposing (href, id)
import Http
import I18n
import Locales.Ids exposing (TranslationId(..))
import MessageToast exposing (MessageToast)
import Money exposing (Currency(..))
import Objectives.Objective exposing (Objective)
import Objectives.Objectives as Objectives
import PreferredAgencyFeeModels
import RemoteData exposing (RemoteData(..))
import Route exposing (Route(..), onUrlChange)
import SharedElmModules.Feature as Feature
import SharedElmModules.Objectives.Objectives as ObjectivesNew
import SharedElmModules.Objectives.ObjectivesType as ObjectivesType
import SharedElmModules.Permissions as Permissions
import Tailwind.Theme exposing (..)
import Tailwind.Utilities exposing (..)
import TrackingPoints
import Url



-- MAIN


main : Program FlagConfig Model Msg
main =
    Browser.element
        { init = init
        , view = view >> Html.Styled.toUnstyled
        , update = update
        , subscriptions = subscriptions
        }



-- MODEL


type alias Model =
    { campaignId : Maybe Int
    , campaignStartsAt : String
    , campaignEndsAt : String
    , campaignView : Maybe CampaignForm.CampaignView
    , categoryId : Int
    , csrfToken : String
    , messageToast : MessageToast Msg
    , page : Page
    , permissions : RemoteData Http.Error Permissions.Permissions
    , route : Maybe Route
    , enabledFeatures : Feature.EnabledFeatures
    , defaultCostTechnologyBuffer : String
    , projectId : Maybe Int
    , currency : Money.Currency
    }


type Msg
    = ChangedUrl String
    | GotPermissions (RemoteData Http.Error Permissions.Permissions)
    | HandleCampaignFormUpdate CampaignForm.Msg
    | HandleObjectivesUpdate Objectives.Msg
    | HandleObjectivesNewUpdate ObjectivesNew.Msg
    | HandlePreferredAgencyFeeModelsUpdate PreferredAgencyFeeModels.Msg
    | UpdatedSimpleMessageToast (MessageToast Msg)
    | HandleTrackingPointsUpdate TrackingPoints.Msg
    | HandleBriefingUpdate Briefing.Msg


type alias FlagConfig =
    { campaignId : Maybe Int
    , campaignStartsAt : String
    , campaignEndsAt : String
    , categoryId : Int
    , csrfToken : String
    , location : String
    , enabledFeatures : List String
    , defaultCostTechnologyBuffer : String
    , projectId : Maybe Int
    , currency : String
    }


type alias TabConfig =
    { isActive : Bool
    , isAllowed : Bool
    , linkName : String
    , url : String
    , icon : FeatherIcons.Icon
    , tabType : TabType
    }



-- INIT


init : FlagConfig -> ( Model, Cmd Msg )
init flags =
    let
        ( model, routingCmd ) =
            routeToPage (Route.fromUrl flags.location) (initialModel flags)
    in
    ( model
    , Cmd.batch
        [ routingCmd
        , Permissions.fetch GotPermissions
        ]
    )


initialModel : FlagConfig -> Model
initialModel flags =
    let
        currency =
            Money.fromString flags.currency |> Maybe.withDefault Money.EUR
    in
    { campaignId = flags.campaignId
    , campaignStartsAt = flags.campaignStartsAt
    , campaignEndsAt = flags.campaignEndsAt
    , campaignView = getCampaignPath flags.location
    , categoryId = flags.categoryId
    , csrfToken = flags.csrfToken
    , messageToast = MessageToast.init UpdatedSimpleMessageToast
    , page = NoView
    , permissions = RemoteData.Loading
    , route = Route.fromUrl flags.location
    , enabledFeatures = Feature.fromStringList flags.enabledFeatures
    , defaultCostTechnologyBuffer = flags.defaultCostTechnologyBuffer
    , projectId = flags.projectId
    , currency = currency
    }


type Page
    = Campaign CampaignForm.Model
    | PreferredAgencyFeeModels PreferredAgencyFeeModels.Model
    | TrackingPoints TrackingPoints.Model
    | Objectives Objectives.Model
    | ObjectivesNew ObjectivesNew.Model
    | Briefing Briefing.Model
    | NoView


type TabType
    = BasicData
    | PreferredAgencyFeeModel
    | TrackingPoint
    | Objective
    | ObjectiveNew
    | BriefingTab



-- SUBSCRIPTIONS


subscriptions : Model -> Sub Msg
subscriptions model =
    Sub.batch
        [ MessageToast.subscriptions model.messageToast
        , onUrlChange ChangedUrl
        , pageSubscriptions model
        ]


pageSubscriptions : Model -> Sub Msg
pageSubscriptions model =
    case model.page of
        Campaign pageModel ->
            Sub.map HandleCampaignFormUpdate (CampaignForm.subscriptions pageModel)

        PreferredAgencyFeeModels pageModel ->
            Sub.map HandlePreferredAgencyFeeModelsUpdate (PreferredAgencyFeeModels.subscriptions pageModel)

        TrackingPoints pageModel ->
            Sub.map HandleTrackingPointsUpdate (TrackingPoints.subscriptions pageModel)

        Objectives pageModel ->
            Sub.map HandleObjectivesUpdate (Objectives.subscriptions pageModel)

        ObjectivesNew pageModel ->
            Sub.map HandleObjectivesNewUpdate (ObjectivesNew.subscriptions pageModel)

        Briefing pageModel ->
            Sub.map HandleBriefingUpdate (Briefing.subscriptions pageModel)

        NoView ->
            Sub.none



-- UPDATE


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case msg of
        ChangedUrl url ->
            routeToPage (Route.fromUrl url) model

        GotPermissions remotePermissions ->
            ( { model | permissions = remotePermissions }, Cmd.none )

        HandleCampaignFormUpdate campaignMsg ->
            case model.page of
                Campaign campaignModel ->
                    let
                        ( updatedCampaign, campaignCmd ) =
                            CampaignForm.update campaignMsg campaignModel
                    in
                    ( { model | page = Campaign updatedCampaign }
                    , Cmd.map HandleCampaignFormUpdate campaignCmd
                    )

                _ ->
                    ( model, Cmd.none )

        HandleObjectivesUpdate objectiveMsg ->
            case model.page of
                Objectives objectivesModel ->
                    let
                        ( updatedObjectivesModel, objectivesCmd ) =
                            Objectives.update objectiveMsg objectivesModel
                    in
                    ( { model | page = Objectives updatedObjectivesModel }
                    , Cmd.map HandleObjectivesUpdate objectivesCmd
                    )

                _ ->
                    ( model, Cmd.none )

        HandleObjectivesNewUpdate objectiveMsg ->
            case model.page of
                ObjectivesNew objectivesModel ->
                    let
                        ( updatedObjectivesModel, objectivesCmd ) =
                            ObjectivesNew.update objectiveMsg objectivesModel
                    in
                    ( { model | page = ObjectivesNew updatedObjectivesModel }
                    , Cmd.map HandleObjectivesNewUpdate objectivesCmd
                    )

                _ ->
                    ( model, Cmd.none )

        HandlePreferredAgencyFeeModelsUpdate agencyFeeModelMsg ->
            case model.page of
                PreferredAgencyFeeModels agencyFeeModel ->
                    let
                        ( updatedAgencyFeeModel, agencyFeeModelCmd ) =
                            PreferredAgencyFeeModels.update agencyFeeModelMsg agencyFeeModel
                    in
                    ( { model | page = PreferredAgencyFeeModels updatedAgencyFeeModel }
                    , Cmd.map HandlePreferredAgencyFeeModelsUpdate agencyFeeModelCmd
                    )

                _ ->
                    ( model, Cmd.none )

        HandleTrackingPointsUpdate trackingPointMsg ->
            case model.page of
                TrackingPoints trackingPointModel ->
                    let
                        ( updatedTrackingPoint, trackingPointCmd ) =
                            TrackingPoints.update trackingPointMsg trackingPointModel
                    in
                    ( { model | page = TrackingPoints updatedTrackingPoint }, Cmd.map HandleTrackingPointsUpdate trackingPointCmd )

                _ ->
                    ( model, Cmd.none )

        HandleBriefingUpdate briefingMsg ->
            case model.page of
                Briefing briefingModel ->
                    let
                        ( updatedBriefing, briefingCmd ) =
                            case model.campaignId of
                                Just campaignId ->
                                    Briefing.update briefingMsg campaignId model.csrfToken briefingModel

                                Nothing ->
                                    ( briefingModel, Cmd.none )
                    in
                    ( { model | page = Briefing updatedBriefing }, Cmd.map HandleBriefingUpdate briefingCmd )

                _ ->
                    ( model, Cmd.none )

        UpdatedSimpleMessageToast updatedMessageToast ->
            ( { model | messageToast = updatedMessageToast }, Cmd.none )



-- VIEW


view : Model -> Html Msg
view model =
    div [ id "campaign-settings" ]
        [ Css.Global.global [ Css.Global.selector ".elm-datetimepicker--picker-container" [ z_10 ] ]
        , Components.viewRemoteData (viewLayout model) model.messageToast model.permissions
        ]


viewLayout : Model -> Permissions.Permissions -> Html Msg
viewLayout model permissions =
    case model.campaignView of
        Just CampaignForm.EditView ->
            let
                isActive route =
                    model.route == route
            in
            ElmViewComponents.viewSidebarLayout
                [ ElmViewComponents.viewSidebarHeader [] [ text <| I18n.t TidMain_campaignSettings ]
                , viewMenuTab
                    { url = "#"
                    , linkName = I18n.t TidMain_basicData
                    , isActive = isActive (Just Route.Campaign)
                    , isAllowed = Permissions.isAccessPermitted (Permissions.ManageCampaignsUpdate model.categoryId) permissions
                    , icon = FeatherIcons.database
                    , tabType = BasicData
                    }
                , viewMenuTab
                    { url = "#briefing"
                    , linkName = "Briefing"
                    , isActive = isActive (Just Route.Briefing)
                    , isAllowed = Permissions.isAccessPermitted (Permissions.ManageCampaignsBriefingRead model.categoryId) permissions
                    , icon = FeatherIcons.paperclip
                    , tabType = BriefingTab
                    }
                , viewMenuTab
                    { url = "#preferred_agency_fee_models"
                    , linkName = I18n.t TidMain_preferredAgencyFeeModels
                    , isActive = isActive (Just Route.PreferredAgencyFeeModels)
                    , isAllowed = Permissions.isAccessPermitted (Permissions.ManageCampaignsUpdatePreferredAgencyFeeModel model.categoryId) permissions
                    , icon = FeatherIcons.percent
                    , tabType = PreferredAgencyFeeModel
                    }
                , viewMenuTab
                    { url = "#campaign_tracking_points"
                    , linkName = I18n.t TidTrackingPoints_title
                    , isActive = isActive (Just Route.TrackingPoints)
                    , isAllowed = Permissions.isAccessPermitted (Permissions.ManageAdserverMetricsManage model.categoryId) permissions
                    , icon = FeatherIcons.trendingUp
                    , tabType = TrackingPoint
                    }
                , if Feature.isEnabled model.enabledFeatures Feature.ObjectivesLegacyUI then
                    viewMenuTab
                        { url = "#objectives"
                        , linkName = I18n.t TidMain_objectivesLegacy
                        , isActive = isActive (Just Route.Objectives)
                        , isAllowed = Permissions.isAccessPermitted (Permissions.ManageCampaignsUpdate model.categoryId) permissions && Feature.isEnabled model.enabledFeatures Feature.CampaignObjectives
                        , icon = FeatherIcons.crosshair
                        , tabType = Objective
                        }

                  else
                    text ""
                , viewMenuTab
                    { url = "#objectives_new"
                    , linkName = I18n.t TidMain_objectives
                    , isActive = isActive (Just Route.ObjectivesNew)
                    , isAllowed = Permissions.isAccessPermitted (Permissions.ManageCampaignsUpdate model.categoryId) permissions && Feature.isEnabled model.enabledFeatures Feature.CampaignObjectives
                    , icon = FeatherIcons.crosshair
                    , tabType = ObjectiveNew
                    }
                ]
                [ viewContent model permissions ]

        Just CampaignForm.CopyView ->
            div [ Attrs.css [ fixed, left_0, top_0, w_full, h_content, mt_header, flex ] ]
                [ div [ Attrs.css [ flex_grow_default, h_full, bg_color brand_grey_light_50 ] ]
                    [ viewContent model permissions ]
                ]

        Just CampaignForm.CreateView ->
            div [ Attrs.css [ fixed, left_0, top_0, w_full, h_content, mt_header, flex ] ]
                [ div [ Attrs.css [ flex_grow_default, h_full, bg_color brand_grey_light_50 ] ]
                    [ viewContent model permissions ]
                ]

        _ ->
            text ""


viewMenuTab : TabConfig -> Html Msg
viewMenuTab tabConfig =
    if tabConfig.isAllowed then
        viewTab tabConfig

    else
        text ""


viewTab : TabConfig -> Html Msg
viewTab { url, linkName, isActive, icon, tabType } =
    let
        state =
            if isActive then
                ElmViewComponents.ActiveState

            else
                ElmViewComponents.DefaultState
    in
    ElmViewComponents.viewSidebarTab [ href url, id (tabId tabType) ]
        { title = linkName, icon = Just icon, state = state }


viewContent : Model -> Permissions.Permissions -> Html Msg
viewContent model permissions =
    case model.route of
        Just route ->
            case route of
                Route.Campaign ->
                    viewPage permissions (Permissions.ManageCampaignsUpdate model.categoryId) Nothing model

                Route.PreferredAgencyFeeModels ->
                    viewPage permissions (Permissions.ManageCampaignsUpdatePreferredAgencyFeeModel model.categoryId) Nothing model

                Route.TrackingPoints ->
                    viewPage permissions (Permissions.ManageAdserverMetricsManage model.categoryId) Nothing model

                Route.Objectives ->
                    viewPage permissions (Permissions.ManageCampaignsUpdate model.categoryId) (Just Feature.CampaignObjectives) model

                Route.ObjectivesNew ->
                    viewPage permissions (Permissions.ManageCampaignsUpdate model.categoryId) (Just Feature.CampaignObjectives) model

                Route.Briefing ->
                    viewPage permissions (Permissions.ManageCampaignsUpdatePreferredAgencyFeeModel model.categoryId) Nothing model

        Nothing ->
            text ""


viewPage : Permissions.Permissions -> Permissions.Permission -> Maybe Feature.Feature -> Model -> Html Msg
viewPage permissions terms requiredFeature model =
    let
        featureAllowed =
            requiredFeature |> Maybe.map (Feature.isEnabled model.enabledFeatures) |> Maybe.withDefault True
    in
    if Permissions.isAccessPermitted terms permissions && featureAllowed then
        subModuleViewer model

    else
        text ""


subModuleViewer : Model -> Html Msg
subModuleViewer model =
    case model.page of
        Campaign pageModel ->
            Html.Styled.map HandleCampaignFormUpdate (CampaignForm.view pageModel)

        PreferredAgencyFeeModels pageModel ->
            Html.Styled.map HandlePreferredAgencyFeeModelsUpdate (PreferredAgencyFeeModels.view pageModel)

        TrackingPoints pageModel ->
            Html.Styled.map HandleTrackingPointsUpdate (TrackingPoints.view pageModel)

        Objectives pageModel ->
            Html.Styled.map HandleObjectivesUpdate (Objectives.view pageModel)

        ObjectivesNew pageModel ->
            Html.Styled.map HandleObjectivesNewUpdate (ObjectivesNew.view pageModel)

        Briefing pageModel ->
            Html.Styled.map HandleBriefingUpdate (Briefing.view pageModel model.categoryId)

        NoView ->
            text ""


routeToPage : Maybe Route -> Model -> ( Model, Cmd Msg )
routeToPage route model =
    case route of
        Just justRoute ->
            case justRoute of
                Route.Campaign ->
                    let
                        ( updatedCampaign, campaignCmd ) =
                            CampaignForm.init
                                { categoryId = model.categoryId
                                , campaignId = model.campaignId
                                , csrfToken = model.csrfToken
                                , campaignStartsAt = model.campaignStartsAt
                                , campaignEndsAt = model.campaignEndsAt
                                , defaultCostTechnologyBuffer = model.defaultCostTechnologyBuffer
                                , campaignView = model.campaignView
                                , projectId = model.projectId
                                }
                    in
                    ( { model
                        | route = Just Route.Campaign
                        , page = Campaign updatedCampaign
                      }
                    , Cmd.map HandleCampaignFormUpdate campaignCmd
                    )

                Route.PreferredAgencyFeeModels ->
                    case model.campaignId of
                        Just campaignId ->
                            let
                                ( updatedAgencyFeeModel, agencyFeeModelCmd ) =
                                    PreferredAgencyFeeModels.init model.csrfToken campaignId
                            in
                            ( { model
                                | route = Just Route.PreferredAgencyFeeModels
                                , page = PreferredAgencyFeeModels updatedAgencyFeeModel
                              }
                            , Cmd.map HandlePreferredAgencyFeeModelsUpdate agencyFeeModelCmd
                            )

                        Nothing ->
                            ( model, Cmd.none )

                Route.TrackingPoints ->
                    case model.campaignId of
                        Just campaignId ->
                            let
                                ( updatedTrackingPoint, trackingPointsCmd ) =
                                    TrackingPoints.init model.csrfToken campaignId
                            in
                            ( { model
                                | route = Just Route.TrackingPoints
                                , page = TrackingPoints updatedTrackingPoint
                              }
                            , Cmd.map HandleTrackingPointsUpdate trackingPointsCmd
                            )

                        Nothing ->
                            ( model, Cmd.none )

                Route.Objectives ->
                    case model.campaignId of
                        Just campaignId ->
                            let
                                ( updatedObjectiveModel, objectivesCmd ) =
                                    Objectives.init model.csrfToken campaignId
                            in
                            ( { model
                                | route = Just Route.Objectives
                                , page = Objectives updatedObjectiveModel
                              }
                            , Cmd.map HandleObjectivesUpdate objectivesCmd
                            )

                        Nothing ->
                            ( model, Cmd.none )

                Route.ObjectivesNew ->
                    case model.campaignId of
                        Just campaignId ->
                            let
                                belongsToProject =
                                    case model.projectId of
                                        Just _ ->
                                            True

                                        Nothing ->
                                            False

                                ( updatedObjectiveModel, objectivesCmd ) =
                                    ObjectivesNew.init model.csrfToken (ObjectivesType.CampaignObjectives campaignId belongsToProject) model.currency
                            in
                            ( { model
                                | route = Just Route.ObjectivesNew
                                , page = ObjectivesNew updatedObjectiveModel
                              }
                            , Cmd.map HandleObjectivesNewUpdate objectivesCmd
                            )

                        Nothing ->
                            ( model, Cmd.none )

                Route.Briefing ->
                    let
                        remotePermissions =
                            model.permissions

                        ( updatedBriefingModel, briefingModelCmd ) =
                            Briefing.init remotePermissions
                    in
                    ( { model
                        | route = Just Route.Briefing
                        , page = Briefing updatedBriefingModel
                      }
                    , Cmd.map HandleBriefingUpdate briefingModelCmd
                    )

        Nothing ->
            ( { model | page = NoView }, Cmd.none )



-- CONVERTS TABTYPE TO STRING FOR FEATURE SPECS


tabId : TabType -> String
tabId tabType =
    case tabType of
        BasicData ->
            "basic-data"

        PreferredAgencyFeeModel ->
            "preferred-agency-fee-model"

        TrackingPoint ->
            "tracking-point"

        Objective ->
            "objective"

        ObjectiveNew ->
            "objective-new"

        BriefingTab ->
            "briefing"


getCampaignPath : String -> Maybe CampaignForm.CampaignView
getCampaignPath location =
    let
        maybeCampaignPath =
            Url.fromString location
    in
    case maybeCampaignPath of
        Just campaignPath ->
            if String.contains "new" campaignPath.path then
                Just CampaignForm.CreateView

            else if String.contains "edit" campaignPath.path then
                Just CampaignForm.EditView

            else if String.contains "copy" campaignPath.path then
                Just CampaignForm.CopyView

            else
                Nothing

        Nothing ->
            Nothing
