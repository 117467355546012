import '@webcomponents/custom-elements'
import 'regenerator-runtime/runtime'
import { theme } from '../shared_elm_modules/tailwind.config.js'
import vegaEmbed from 'vega-embed'

const { colors, fontFamily } = theme

export class VegaElement extends HTMLElement {
  constructor() {
    super()
    this._chart = null
    this._spec = null
    // create the chart element holder
    this._chartElement = document.createElement('div')
  }

  static get observedAttributes() {
    return ['chart-spec']
  }

  connectedCallback() {
    // Append the chart element holder as child
    this.appendChild(this._chartElement)
  }

  disconnectedCallback() {
    this._chart.finalize()
  }

  attributeChangedCallback() {
    this._spec = JSON.parse(this.getAttribute('chart-spec'))
    console.log('VegaElement chart-spec update? ', this._spec)
    this.createChart(this._spec)
  }

  createChart(spec) {
    this.app = window.CampaignSettings.app
    // create the chart in the holder
    vegaEmbed(this._chartElement, spec, config).then((res) => {
      this._chart = res.view // Access the view
      this._chart.addEventListener('click', (event, item) => {
        if (item) {
          this._chartElement.dispatchEvent(
            new CustomEvent('vegaItemClick', {
              bubbles: true,
              detail: item.datum,
            })
          )
        }
      })
    })
  }
}

customElements.define('vega-element', VegaElement)

const config = {
  actions: false,
  config: {
    autosize: 'fit',
    locale: {
      number: {
        decimal: ',',
        thousands: '.',
        grouping: [3],
      },
      time: {
        dateTime: '%A, der %e. %B %Y, %X',
        date: '%d.%m.%Y',
        week: 'KW%U ',
        time: '%H:%M:%S',
        yearWeek: '%d.%m.%Y',
        periods: ['AM', 'PM'],
        days: [
          'Sonntag',
          'Montag',
          'Dienstag',
          'Mittwoch',
          'Donnerstag',
          'Freitag',
          'Samstag',
        ],
        shortDays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
        months: [
          'Januar',
          'Februar',
          'März',
          'April',
          'Mai',
          'Juni',
          'Juli',
          'August',
          'September',
          'Oktober',
          'November',
          'Dezember',
        ],
        shortMonths: [
          'Jan',
          'Feb',
          'Mrz',
          'Apr',
          'Mai',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Okt',
          'Nov',
          'Dez',
        ],
      },
    },
    font: fontFamily.sans,
    title: {
      color: colors['brand-grey'].default,
      subtitleColor: colors['brand-grey'].default,
      anchor: 'start',
    },
    axis: {
      gridColor: colors['brand-grey-light'][75],
      labelColor: colors['brand-grey-light'][500],
      labelPadding: 10,
      titleColor: colors['brand-grey-light'][500],
      titlePadding: 10,
      tickCap: 'round',
      tickColor: colors['brand-grey-light'][75],
      domainColor: colors['brand-grey-light'][75],
    },
    range: {
      category: [
        '#2B67A5',
        '#4CA8A2',
        '#EBDF4D',
        '#A5C366',
        '#BC4663',
        '#D96841',
        '#3983B6',
        '#4BA469',
        '#DF9345',
        '#794787',
        '#779DC5',
        '#8DC7C4',
        '#F2EA8D',
        '#C5D99D',
        '#D4899B',
        '#E79E85',
        '#81B0D1',
        '#8CC59F',
        '#EABA88',
        '#A989B2',
      ],
    },
    axisX: {},
    axisY: {},
    mark: {},
    view: {
      stroke: 0,
      fill: colors['brand-grey-light'][25],
      cornerRadius: 2,
    },
    bar: {
      color: colors['brand-cyan'].default,
      cornerRadius: 2,
    },
    line: {
      stroke: colors['brand-cyan'].default,
    },
    text: {
      color: colors['brand-grey'].default,
      align: 'left',
      dx: 5,
    },
  },
}
