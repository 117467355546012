class FormattedInput extends HTMLElement {
  static get observedAttributes() {
    return ["input-value", "input-style", "input-id", "input-placeholder"];
  }

  constructor() {
    super();

    const shadow = this.attachShadow({ mode: "open" });
    this._input = document.createElement("input");
    this._input.setAttribute("type", "text");
    shadow.appendChild(this._input);
  }

  connectedCallback() {
    this._input.addEventListener("input", () => {
      this.dispatchEvent(new CustomEvent("inputChanged"));
      if (!this.input.match(this.getAttribute("pattern"))) {
        this.input = this.getAttribute("input-value");
      }
    });

    this.handleFieldsetInfluence();
  }

  disconnectedCallback() {
    if (this._fieldsetObserver) {
      this._fieldsetObserver.disconnect();
    }
  }

  attributeChangedCallback(name, _oldValue, newValue) {
    if (name === "input-value") {
      this.input = newValue;
    } else if (name == "input-style") {
      this._input.setAttribute("style", newValue);
    } else if (name == "input-id") {
      this._input.setAttribute("id", newValue);
    } else if (name == "input-placeholder") {
      this._input.setAttribute("placeholder", newValue);
    }
  }

  handleFieldsetInfluence() {
    this._disabledOriginal = this.disabled;
    const fieldset = this.closest("fieldset");

    if (fieldset) {
      const handleFieldsetState = (fieldset) => {
        this._disabledByFieldset = fieldset.disabled;

        if (this._disabledByFieldset) {
          this._input.setAttribute("disabled", "");
        } else {
          this.disabled = this._disabledOriginal;
        }
      };

      if (fieldset.disabled) {
        handleFieldsetState(fieldset);
      }

      this._fieldsetObserver = new MutationObserver((mutationsList) => {
        handleFieldsetState(mutationsList[0].target);
      });

      this._fieldsetObserver.observe(fieldset, {
        attributes: true,
        attributeFilter: ["disabled"],
      });
    }
  }

  get input() {
    return this._input.value;
  }

  set input(newValue) {
    let oldLength = this._input.value.length;
    let oldIdx = this._input.selectionStart;
    this._input.value = newValue;
    let newIdx = Math.max(0, this._input.value.length - oldLength + oldIdx);
    this._input.selectionStart = this._input.selectionEnd = newIdx;
    this._input.value = newValue;
  }

  get disabled() {
    return this._input.hasAttribute("disabled");
  }

  set disabled(val) {
    this._disabledOriginal = val;

    if (this._disabledByFieldset) {
      return;
    } else if (val) {
      this._input.setAttribute("disabled", "");
    } else {
      this._input.removeAttribute("disabled");
    }
  }
}

customElements.define("formatted-input", FormattedInput);
