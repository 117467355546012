import { Elm } from '../src/Main.elm'
import '../node_modules/@webcomponents/webcomponentsjs/webcomponents-bundle.js'
import '../shared_elm_modules/src/formatted-input.js'
import './vega-element'

window.CampaignSettings = {
  init: function ({
    node,
    campaignId,
    campaignStartsAt,
    campaignEndsAt,
    categoryId,
    csrfToken,
    enabledFeatures,
    defaultCostTechnologyBuffer,
    projectId,
    currency
  }) {
    const app = Elm.Main.init({
      node: node,
      flags: {
        campaignId: campaignId,
        campaignStartsAt: campaignStartsAt,
        campaignEndsAt: campaignEndsAt,
        categoryId: categoryId,
        csrfToken: csrfToken,
        location: location.href,
        enabledFeatures: enabledFeatures,
        defaultCostTechnologyBuffer: defaultCostTechnologyBuffer,
        projectId: projectId,
        currency: currency
      },
    })

    window.addEventListener('hashchange', function () {
      app.ports.onUrlChange.send(location.href)
    })
  },
}
